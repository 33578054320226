<template>
  <div>
    <Header/> 
    <div class="content_text">
      <!-- <router-link :to="{path:'/'}">
        <img class="content_img_1s" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="content_img_2s" src="../../assets/search_result/search_icon.png">
        <div calss="content_study">
          <div class="pagetitle-con-title1">搜索結果</div>
          <div class="pagetitle-con-title2">#{{content}}</div>
        </div>
    </div>
    <div v-loading="loading">
      <div style="padding: 30px; text-align: center;color:#fff;" v-if="status">{{search_result}}</div>
      
      <div v-else>
          <div class="lesson-content-title-bar" v-if="contentList['kc_count'] > 0">{{contentList['kc_name']}}[{{contentList['kc_count']}}]</div>
          <div v-for="items in contentList['kc_content']" :key="items" :class="{search_result:port == 'phone',search_results:port != 'phone'}">
            <router-link :to="{path:'/training_schedule',query:{
              type:items.id
            }}">
            <img :src="items.src"  style="width:100%;height:100%;" />
              <!-- <div class="vip-icons">
                <img src="../../assets/content/radio_selected.png">
              </div> -->
            </router-link>
          </div>

          <div class="lesson-content-title-bar" v-if="contentList['kt_count'] > 0">{{contentList['kt_name']}}[{{contentList['kt_count']}}]</div>
          <div class="lesson-item" v-for="items in contentList['kt_content']" :key="items" @click="lessonItem(items.id)">
              <div class="lesson-item_1_1">
                <img :src="items.src">
              </div>
              <div class="lesson-item_2">
                  <p class="lesson-item_p1">{{items.title}}</p>
                  <p class="lesson-item_p2">{{items.sub_title}}</p>
                  <p class="lesson-item_p3">{{items.third_title}}</p>
              </div>
          </div>
      </div>
    </div>
   
    <div class="sv_4">
      <div style="width:100%;height:90px;"></div>
        <div style="width:100%;height:90px;"></div>
    </div>

    <Footer />
  </div>
</template>

<style>
/* //修改loading加载时的颜色 */
    .el-loading-spinner .path{
      stroke: blue;
    }
.lesson-item_p1{
  font-family: Helvetica Neue;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.lesson-item_p2{
  font-family: Helvetica Neue;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  color: rgba(0,0,0,.7);
}
.lesson-item_p3{
    font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.lesson-content-title-bar {
  height: 40px;
  background: rgba(0,0,0,.5);
  padding-left: 15px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 700;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  color:#fff;
  text-align: left;
}
.lesson-item_2{
  width:63%;
  height:auto;
  margin:auto 5px auto 5px;
}
.lesson-item_1_1{
  width: 125px;
  height: auto;
}
.lesson-item_1_1 img{
  width: 100%;
  height:100%;
}
.lesson-item{
  height: auto;
  border-bottom: 1px solid #fff;
  display: flex;
  position: relative;
  min-height: 70px;
}
.lesson-item_2 p{
  margin: 0px 0 0 0;
  word-wrap: break-word;
}
.vip-icons img{
  margin-top: 55px;
  width: 20px;
  height: 20px;
}
.vip-icons{
  position: absolute;
  padding: 0;
  right: 5px;
  top: 0;
  bottom: 0;
  /* background-color: rgba(255, 0, 0, 0.3); */
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-filter: drop-shadow(0 0 4px #d4b661);
  filter: drop-shadow(0 0 4px #d4b661);
}
  .search_result{
   width: 100%;
   height: 7rem;
   border-bottom: 1px solid #fff;
 }
 .search_results{
    width: 100%;
   height: 18rem;
   border-bottom: 1px solid #fff;
 }
  .sv_4{
    height: auto;
    width: 100%;
    float:left;
    overflow: hidden auto;
  }
  .content_study{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_img_1s{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .content_img_2s{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
import Footer from "../Footer";
import {apiSearch} from "@/request/api";
export default {
  components:{Header,Footer},
  data(){
    return {
      content:'',
      status:false,
      contentList:[],
      loading:false,
      search_result:'',
      port:'',
    }
  },
  created(){

    let lang_id = this.$store.state.lang_id;
    if(lang_id == 1){
      this.search_result = this.$zh_ft('search_result');
    }else{
      this.search_result = this.$zh_jt('search_result');
    }

    if (!this._isMobile()) {
      this.port = 'pc';
    }else if(this._isMobile()[0] == 'iPad'){
        this.port = 'iPad';
    }else {
      this.port = 'phone';
    }

    let content = this.$route.query.content;
    let kc_content = [];
    let kt_content = [];
    this.content = content;
    let that = this;
    that.loading = true;
    apiSearch({
      search_keyword:content,
      lang_id:this.$store.state.lang_id
    }).then(res=>{
      if(res.code == 200){
        for(let kc=0; kc< res.data.courseTypeList.length; kc++){
          kc_content.push({
            src:res.data.courseTypeList[kc].image,
            id:res.data.courseTypeList[kc].id,
          });
        }

        for(let kt=0; kt< res.data.courseList.length; kt++){
          kt_content.push({
            src:res.data.courseList[kt].cover,
            id:res.data.courseList[kt].id,
            title:res.data.courseList[kt].title,
            sub_title:res.data.courseList[kt].sub_title,
            third_title:res.data.courseList[kt].third_title
          });
        }

        if(kc_content.length == 0 && kt_content.length == 0){
          that.status =true
          that.loading = false;
          return false;
        }
        if(kc_content.length > 0){
          that.contentList['kc_name'] = '課程';
          that.contentList['kc_content'] =kc_content;
          that.contentList['kc_count'] = kc_content.length;
        }
        if(kt_content.length > 0){
          that.contentList['kt_name'] = '課堂';
          that.contentList['kt_content'] =kt_content;
          that.contentList['kt_count'] = kt_content.length;
        }
      }
      that.loading = false;
    }).catch(error=>{
      that.loading = false;
      console.log(error);
    })

  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    lessonItem(id = 0){
      this.$router.push({
        path:'/training_schedule/'+id
      });
    }
  }
}
</script>

